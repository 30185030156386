import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import { OdataItems } from '@/shared/model/OdataItems';
import driveType, { DriveType } from '@/shared/model/driveType';
import driveTypesImportSetting, { DriveTypesImportSetting } from '@/shared/model/driveTypesImportSetting';
import { Organisation } from '@/shared/model/organisation';
import D4ySwitch from '@/components/d4y-switch/d4y-switch.vue';
import ImportTableView from '@/views/settings/company/account-assignment/import-table/import-table.vue';

const name = 'drive-types-view';
const logger = new Logger(name);
const driveTypeModule = namespace('driveType');
const driveTypesImportSettingModule = namespace('driveTypesImportSetting');
const organizationModule = namespace('organization');

@Component({ name: name, components: { ImportTableView } })
export default class DriveTypesView extends Vue {
  @driveTypesImportSettingModule.Action('updateChangeClearBeforeImport')
  private updateChangeClearBeforeImport!: any;
  @driveTypesImportSettingModule.Action('getDriveTypesImportSetting')
  private getDriveTypesImportSetting!: any;
  @driveTypesImportSettingModule.Getter('getDriveTypesImportSetting')
  private driveTypesImportSetting!: any;
  @driveTypesImportSettingModule.Getter('getInProgressUpdateDriveTypesImportSetting')
  private inProgressUpdateDriveTypesImportSetting!: boolean;
  @driveTypesImportSettingModule.Getter('getDriveTypesImportSettingsIsLoading')
  private driveTypesImportSettingsIsLoading!: boolean;
  @driveTypeModule.Action('uploadDriveType')
  private uploadDriveType!: any;

  @driveTypeModule.Action('getDriveTypes')
  private getDriveTypes!: any;
  @driveTypeModule.Getter('getDriveTypes')
  private driveTypes!: OdataItems<DriveType>;
  @driveTypeModule.Getter('getIsImporting')
  private isImporting!: boolean;

  get headers() {
    const headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('column0'), value: 'value' },
      { text: this.$t('column1'), value: 'name' },
    ];
    return headers;
  }

  async mounted() {}
}
